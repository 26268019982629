import DOMPurify from "isomorphic-dompurify";

import { MenuItem } from "@/components/layout/Header/Header";
import ENDPOINTS from "@/constants/endpoints";
import URLS from "@/constants/urls";
import {
  ForumOverviewPage,
  ForumPostType,
  VolunteerTrainingDay,
} from "@/types/forumTypes";
import { GeneralInformation } from "@/types/generalInformationTypes";
import { MilestonesPageData, PagedMilestones } from "@/types/milestonesTypes";
import { BobbyForm, GenderOption } from "@/types/schemas/bobby";

type LetterRequest = {
  excludedIds?: string[];
} & DirectusParams;

type HomepageParams = {
  /** The age category */
  ageCategory?: string;

  /** Selected category id */
  category?: string;

  page?: number;
};

type DirectusParams = {
  /** The amount of items that will be fetched, set -1 to retrieve all */
  postPerPage?: number;

  /** If post per page is set, you can use this param to paginate */
  page?: number;

  /** Value that will be search in all the valid fields */
  search?: string;

  /** Syntax to use is field for ascending or -field for descending */
  sort?: string;

  /** Syntax to use is filter[field][operator]=value example: filter[id][_eq]=1 */
  filter?: string;

  /** Get meta information, like filter_count */
  meta?: string;

  /** Selected category id */
  category?: string;

  /** Filter by comment count */
  maxCommentCount?: string | string[];
  signal?: AbortSignal;
};

type SteunParams = {
  /** The amount of items that will be fetched, set -1 to retrieve all */
  postPerPage?: number;

  /** Value that will be search in all the valid fields */
  search?: string;

  /** Type of content it the result is filtered on */
  typeContent?: string;

  /** Syntax to use is field for ascending or -field for descending */
  sort?: string;

  /** Syntax to use is filter[field][operator]=value example: filter[id][_eq]=1 */
  filter?: string;

  /** Selected category id */
  category?: string;

  /** Last Blog Id */
  blogId?: string;

  /** Last Vlog Id */
  vlogId?: string;

  /** Last assignment Id */
  assignmentId?: string;

  /** Last audio Id */
  audioId?: string;

  /** page */
  page: number;
};

/**
 * Add a forumpost
 * @param data
 */
export const postForum = async (data: any) => {
  return fetch(`${URLS.APP}/forum/forum-post`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  });
};

/**
 * Update any Forum data ( Here I am using this to update the Likes Count. )
 * @param forumId
 */
export const updateForumDetails = async (forumId: string, data: any) => {
  const updatedData = JSON.stringify(data);
  return await fetch(`${ENDPOINTS.COLLECTIONS}/forum_posts/${forumId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: updatedData,
  });
};

export const postEntityReaction = async (
  entityId: string,
  entityType: string,
  isLiked: boolean,
) => {
  const response = await fetch(`${URLS.APP}/reaction/reaction`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      entityId,
      entityType,
      reactionType: isLiked ? "like" : "dislike",
    }),
  });

  return await response.json();
};

/**
 * Add a comment
 * @param type "forum" or "blog"
 * @param data
 */
export const postComment = async (
  type: "forum" | "blog" | "open_letter" | "tip" | "audio" | "assignment",
  { post_id, ...rest }: any,
) => {
  if (rest.parent_id) {
  } else {
    const res = await fetch(`${URLS.APP}/comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...rest,
        content: DOMPurify.sanitize(rest.content),
        [`${type.includes("letter") ? type : `${type}_post`}`]: post_id,
        status: "draft",
        entityType: type,
      }),
    });
  }
};

/**
 * Get all the comments of a post
 * @param type the type of post
 * @param post_id
 * @returns
 */
export const getComments = async (
  type: "forum" | "blog" | "open_letter" | "tip",
  post_id: string,
) => {
  return await fetch(`${URLS.APP}/comment/get/${type}/${post_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Get all the menu items used for navigation
 * @returns array or null
 */
export const getMenuItems = async () => {
  try {
    const res = await fetch(`${URLS.APP}/menu-items`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const resData = await res.json();
    return resData as MenuItem[];
  } catch (error) {
    return null;
  }
};

/**
 * Get the general information of the website
 * @returns GeneralInformationTypes or null (if error)
 */
export const getGeneralInformation = async () => {
  try {
    const response = await fetch(`${URLS.APP}/general-information`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    return (await response.json()) as GeneralInformation;
  } catch (error) {
    return null;
  }
};

/**
 * get the top home page details
 *
 */
export const getHomeTopPageData = async ({
  ageCategory,
  category,
  page,
}: HomepageParams) => {
  let url = `${URLS.APP}/homepage/home-top-data-without-email?BlogPostCount=1&ForumCount=1&InstagramCount=1&ChatItemCount=1&AudioItemCount=1&VideoItemCount=1&AssignmentCount=1&OpenLetterCount=1`;
  if (ageCategory) {
    url = `${url}&ageCategory=${ageCategory}`;
  }
  if (category) {
    url = `${url}&category=${category}`;
  }
  if (page) {
    url = `${url}&page=${page}`;
  }

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Get the audio items
 * @returns array or null
 */
export const getAudioItems = async ({
  sort,
  page,
  filter,
  search,
  category,
  signal,
}: DirectusParams) => {
  let url = `${URLS.APP}/audio-items`;
  if (search) {
    url = `${url}?search=${search}`;
  }
  if (filter) {
    url = `${url}&filter=${filter}`;
  }
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });
};

/**
 * Get the assignment items
 * @returns array or null
 */
export const getAssignments = async ({
  sort,
  page,
  filter,
  search,
  category,
  signal,
}: DirectusParams) => {
  let url = `${URLS.APP}/assignments`;
  if (search) {
    url = `${url}?search=${search}`;
  }
  if (filter) {
    url = `${url}&filter=${filter}`;
  }
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });
};

/**
 * Get the assignment items
 */
export const getAssignmentDetail = async (slug: string) => {
  const response = await fetch(`${URLS.APP}/assignments?slug=${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result?.[0];
};

/**
 * Get a list of all published open letters
 * @returns
 */
export const getLetters = async ({
  sort,
  filter,
  search,
  excludedIds,
  signal,
}: LetterRequest) => {
  const params = new URLSearchParams();

  if (search) {
    params.append("Search", search);
  }

  if (sort) {
    params.append("Sort", sort);
  }

  if (excludedIds) {
    for (const id of excludedIds) {
      params.append("ExcludedIds", id);
    }
  }

  let url = `${URLS.APP}/open-letters-without-email?${params.toString()}`;

  if (filter) {
    url = `${url}&${filter}`;
  }

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });
};

/**
 * Add a letter subscription
 * @param data
 */
export const postLetterSubscription = async (data: any) => {
  await fetch(`${ENDPOINTS.COLLECTIONS}/letter_submissions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const postLetterDownload = async (data: any) => {
  await fetch(`${ENDPOINTS.COLLECTIONS}/document_download`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const getSupportPosts = async ({
  typeContent,
  sort,
  search,
  category,
  postPerPage,
  page,
}: SteunParams) => {
  let url = `${URLS.APP}/support-overview-page/entities`;
  let prepend = "?";
  if (postPerPage) {
    url = `${url}${prepend}maxresultcount=${postPerPage}`;
    prepend = "&";
  }
  if (page) {
    url = `${url}${prepend}page=${page}`;
  }
  if (search) {
    url = `${url}${prepend}search=${search}`;
  }
  if (category) {
    url = `${url}${prepend}category=${category}`;
  }
  if (sort) {
    url = `${url}${prepend}sort=${sort}`;
  }
  if (typeContent) {
    url = `${url}${prepend}typeContent=${typeContent}`;
  }
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Gets a list of blog posts
 * @param postPerPage the amount of posts to be shown per page
 * @param page the current paginated page
 * @param query the search query
 * @returns
 */
export const getPosts = async ({
  sort,
  filter,
  page,
  search,
  category,
  signal, // Accept the signal as a parameter
}: DirectusParams) => {
  let url = `${URLS.APP}/blog-posts`;
  let prepend = "?";

  if (page) {
    url = `${url}${prepend}page=${page}`;
    prepend = "&";
  }
  if (search) {
    url = `${url}${prepend}search=${search}`;
    prepend = "&";
  }
  if (sort) {
    url = `${url}&sort=${sort}`;
  }
  if (filter) {
    url = `${url}&${filter}`;
  }
  if (category) {
    url = `${url}&category=${category}`;
  }

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });
};

/*get a list of tips */
export const getTips = async () => {
  let url = `${URLS.APP}/tips`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Gets a list of blog posts
 * @param postPerPage the amount of posts to be shown per page
 * @param page the current paginated page
 * @param query the search query
 * @returns
 */
export const getTikTokPosts = async ({
  postPerPage,
  page = 1,
  search,
  sort,
  filter,
  meta = "total_count",
}: DirectusParams) => {
  let url = `${ENDPOINTS.COLLECTIONS}/tiktok_embed?fields=*.*.*&filter[status][_eq]=published&limit=${postPerPage}&page=${page}`;

  if (meta) {
    url = `${url}&meta=${meta}`;
  }
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort) {
    url = `${url}&sort=${sort}`;
  }
  if (filter) {
    url = `${url}&${filter}`;
  }

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Get the post detail base on the slug
 * @param slug the post slug
 * @returns
 */
export const getPostDetail = async (slug: string) => {
  const response = await fetch(`${URLS.APP}/blog-posts?slug=${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result?.data?.[0];
};

/**
 * Get the audio detail base on the slug
 * @param slug the post slug
 * @returns
 */
export const getAudioDetail = async (slug: string) => {
  const response = await fetch(`${URLS.APP}/audio-items?slug=${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result?.[0];
};

/**
 * Gets the Forum overview page details
 */
export const getForumOverviewPageData = async () => {
  const response = await fetch(`${URLS.APP}/forum-overview-page`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (await response.json()) as ForumOverviewPage;
};

/**
 * Gets a list of forum posts
 * @param postPerPage the amount of posts to be shown per page
 * @param page the current paginated page
 * @param query the search query
 * @returns
 */
export const getForumPosts2 = async ({
  sort,
  page,
  filter,
  search,
  category,
  maxCommentCount,
  signal,
}: DirectusParams) => {
  let url = `${URLS.APP}/forum-posts?maxresultcount=10`;
  let prepend = "&";
  if (page) {
    url = `${url}${prepend}page=${page}`;
  }
  if (search) {
    url = `${url}${prepend}search=${search}`;
  }
  if (sort) {
    url = `${url}&sort=${sort}`;
  }
  if (filter) {
    url = `${url}&${filter}`;
  }
  if (category) {
    url = `${url}&category=${category}`;
  }
  if (maxCommentCount) {
    url = `${url}&maxCommentCount=${maxCommentCount}`;
  }

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });
};

/**
 * Gets the data for the homepage
 * @returns
 */
export const getHomeData = async (isForAdults: boolean = false) => {
  const searchParams = new URLSearchParams({
    isForAdults: isForAdults.toString(),
  });

  return await fetch(`${URLS.APP}/homepage?${searchParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Gets the data for the the book page
 * @returns
 */
export const getTheBookData = async () => {
  return await fetch(`${ENDPOINTS.COLLECTIONS}/the_book?fields=*.*.*`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Add a volunteer application
 * @param data
 */
export const postVolunteerApplication = async (data: {
  user_email: string;
  your_initials: string;
  birth_date: Date | undefined;
  other_reference_from?: string;
  entity_id: null;
  uri: string;
  geslacht: GenderOption | undefined;
  langcode: string;
  entity_type: null;
  know_about_us: string;
  surname: string;
  webform_id: string;
  phone_number: string;
  in_draft: boolean;
  trainingDayId: string;
  residence: string;
  motivatie: string;
  first_name: string;
}) => {
  await fetch(`${URLS.APP}/volunteer-registration-form`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  });
};

export const postBobbyApplication = async (data: BobbyForm) => {
  return fetch(`${URLS.APP}/kid-registration`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...data,
      // Set the birthdate to a string in YYYY-MM-DD format
      birthdate: new Date(data.birthdate).toISOString().split("T")[0],
    }),
  });
};

/**
 * Gets all available tags
 */
export const getContentTags = async () => {
  let url = `${URLS.APP}/theme?MaxResultCount=999&status=1`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getSpotifyItems = async () => {
  let url = `${URLS.APP}/spotify-item`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Gets a list of faq items
 * @param postPerPage the amount of items to be shown per page
 * @param page the current paginated page
 * @param query the search query
 * @returns
 */
export const getFaqs = async ({
  // postPerPage,
  // page = 1,
  search,
  sort,
  filter,
  meta = "total_count",
  type = "volunteer_faq",
}: DirectusParams & { type?: string }) => {
  let url = `${ENDPOINTS.COLLECTIONS}/faq_items?fields=*.*.*?filter[status][_eq]=published&filter[type][_eq]=${type}`;

  if (meta) {
    url = `${url}&meta=${meta}`;
  }
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort) {
    url = `${url}&sort=${sort}`;
  }
  if (filter) {
    url = `${url}&${filter}`;
  }

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Gets the data for the faq overview page
 * @returns
 */
export const getFaqOverviewData = async () => {
  return await fetch(`${ENDPOINTS.COLLECTIONS}/faq_overview_page?fields=*.*`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Get the tip detail base on the slug
 * @param slug the post slug
 * @returns
 */
export const getTipDetail = async (slug: string) => {
  return await fetch(`${URLS.APP}/tips?slug=${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Get the page data for the milestones page.
 *
 * @returns MilestonesPageData
 */
export const getMilestonesPageData: () => Promise<MilestonesPageData> =
  async () => {
    const response = await fetch(`${URLS.APP}/milestones-page`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data as MilestonesPageData;
  };

/**
 * Get all the milestones
 *
 * @returns MilestoneType[]
 */
export const getMilestones = async (
  skipCount: number,
  maxResultCount: number,
) => {
  const response = await fetch(
    `${URLS.APP}/milestone?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}&Sorting=order`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return (await response.json()) as PagedMilestones;
};

export const getContentFooterImageId = async () => {
  const req = getGeneralInformation();

  return req.then((res) => res?.contentFooterImageId);
};

/**
 * Get an open letter by slug.
 * @param slug
 * @returns Promise<any> - The open letter data
 */
export const getOpenLetter = async (slug: string): Promise<any> => {
  const response = await fetch(`${URLS.APP}/open-letter/by-slug?slug=${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await response.json();
};

/**
 * Get the forum post
 * @param slug the post slug
 *
 * @returns ForumPostType | undefined
 */
export const getForumPost = async (
  slug: string,
): Promise<ForumPostType | undefined> => {
  const response = await fetch(`${URLS.APP}/forum-posts?slug=${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result?.data?.[0];
};

/**
 * Get the page data for the volunteer register page.
 *
 * @returns Promise<any> - The page data
 */
export const getVolunteerRegisterPageData = async () => {
  const response = await fetch(`${URLS.APP}/volunteer-register`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await response.json();
};

/**
 * Get the volunteer training days.
 *
 * @returns Promise<any> - The training days
 */
export const getTrainingDays = async (): Promise<VolunteerTrainingDay[]> => {
  const response = await fetch(
    `${URLS.APP}/volunteer-training-day/volunteer-training-days`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return await response.json();
};
