import { GetServerSidePropsContext } from "next";

import { getContentTags } from "@/api/api";
import { BlogTypeValues } from "@/enums/blogTypeValues";
import { Tag } from "@/types/contentTypes";

/**
 * Takes an URL String and removes query params and hash params
 *
 * @param url - The URL string
 * @returns The transformed URL string
 *
 */
export const getPathFromUrl = (url: string): string => {
  return url.split(/[?#]/)[0];
};

/**
 * Detects if a given url leads to an internal path or not
 * @param url
 */
export const isInternalLink = (url: string): boolean => {
  // regex to check to check full urls and urls like //ajax.googleapis.com/
  const internalUrlRegex = /^\/\/|^\/(?!\/)/;

  return internalUrlRegex.test(url);
};

/**
 * Transforms a string into a slug
 * @param str string
 * @returns string
 */
export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

/**
 * Encode url for tags by:
 * - Lowercasing the tag
 * - Replacing spaces with hyphens
 * - Encoding the string
 *
 * @param tag
 * @returns string with encoded url
 */
export const encodeTagForURL = (tag: string) => {
  return encodeURIComponent(tag.toLowerCase().replace(/\s/g, "-"));
};

/**
 * Decode tag from url by:
 * - Lowercasing the tag
 * - Replacing hyphens with spaces
 * - Decoding the string
 *
 * @param tag
 * @returns string with decoded url
 */
export const decodeTagFromUrl = (tag: string) => {
  return decodeURIComponent(tag.toLowerCase().replace(/-/g, " "));
};

/**
 * Retrieves the categories from a slug.
 * @param slug - The slug string or array of slug strings.
 * @returns An array of decoded category tags.
 */
const getCategoriesFromSlug = (slug: string | string[]) => {
  return Array.isArray(slug)
    ? slug.slice(0, -1).map(decodeTagFromUrl)
    : [decodeTagFromUrl(slug[0])];
};

/**
 * Retrieves the lowercase names of the categories associated with a content item.
 * @param contentItem - The content item object.
 * @returns An array of lowercase category names.
 */
const getContentItemCategories = (contentItem: any) => {
  return contentItem.categories.map((category: Tag) =>
    category.name.toLowerCase(),
  );
};

/**
 * Encodes and joins an array of categories into a URL path.
 *
 * @param categories - An array of categories to encode and join.
 * @returns The encoded and joined URL path.
 */
const encodeAndJoinCategories = (categories: string[]) => {
  return categories.map(encodeTagForURL).join("/");
};

/**
 * Checks if every category in the slug is valid.
 *
 * @param categoriesInSlug - An array of categories in the slug.
 * @param categories - An array of all available categories.
 * @returns A boolean indicating if every category in the slug is valid.
 */
const isEverySlugCategoryValid = (
  categoriesInSlug: string[],
  categories: any[],
) => {
  return categoriesInSlug.every((category) =>
    categories.some((tag: Tag) => tag.name.toLowerCase() === category),
  );
};

/**
 * Checks if every slug category is present in the content item categories.
 *
 * @param categoriesInSlug - An array of slug categories.
 * @param contentItemCategories - An array of content item categories.
 * @returns A boolean indicating whether every slug category is present in the content item categories.
 */
const isEverySlugCategoryInContentItemCategories = (
  categoriesInSlug: string[],
  contentItemCategories: string[],
) => {
  return categoriesInSlug.every((category) =>
    contentItemCategories.includes(category),
  );
};

/**
 * Validate if the url contains the themes in the correct order and redirect if
 * not. If the url is correct, return the server side props.
 *
 * @param ctx - GetServerSidePropsContext object, provided by getServerSideProps
 * @param getContent - Function to get the content that should be returned in the props
 * @param getContentItem - Function to get content item by the slug
 * @param contentUrlPrefix - Prefix of the content url (e.g. "/verhalen")
 */
export const validateAndRedirectSlugWithThemes = async (
  ctx: GetServerSidePropsContext,
  getContent: (_contentItem: any) => Promise<any>,
  getContentItem: (_slug: string) => Promise<any>,
  contentUrlPrefix: string,
) => {
  let slug = (
    Array.isArray(ctx.query.slug) ? ctx.query.slug : [ctx.query.slug]
  ) as string[];

  const contentSlug = slug.slice(-1)[0] as string;
  const contentItem = await getContentItem(contentSlug);

  if (!contentItem) return { redirect: { destination: "/404" } };

  const contentItemCategories = getContentItemCategories(contentItem);

  // If the content item has no categories
  if (contentItemCategories.length === 0) {
    // Redirect to 404 if the slug has more than one item (i.e. categories)
    if (slug.length > 1) return { notFound: true };

    // Return the content if only the slug is provided
    return { props: { ...(await getContent(contentItem)) } };
  }

  const contentItemCategoriesUrl = encodeAndJoinCategories(
    contentItemCategories,
  );

  // Redirect to the URL with the correct categories if only the contentItem slug is provided
  if (slug.length === 1 || slug[0] === contentSlug) {
    return {
      redirect: {
        destination: `${contentUrlPrefix}/${contentItemCategoriesUrl}/${contentSlug}`,
      },
    };
  }

  const categoriesInSlug = getCategoriesFromSlug(slug);
  const allCategories = (await (await getContentTags()).json()).items;

  const everySlugCategoryIsValidCategory = isEverySlugCategoryValid(
    categoriesInSlug,
    allCategories,
  );

  const everySlugCategoryIsInContentItemCategories =
    isEverySlugCategoryInContentItemCategories(
      categoriesInSlug,
      contentItemCategories,
    );

  // Redirect to 404 if there are categories in the URL that don't exist or are not in the contentItem
  if (
    !everySlugCategoryIsValidCategory ||
    !everySlugCategoryIsInContentItemCategories
  ) {
    return { notFound: true };
  }

  const slugCategoriesUrl = encodeAndJoinCategories(categoriesInSlug);

  // Fix the URL if the categories in the URL are not in the correct order
  if (contentItemCategoriesUrl !== slugCategoriesUrl) {
    return {
      redirect: {
        destination: `${contentUrlPrefix}/${contentItemCategoriesUrl}/${contentSlug}`,
      },
    };
  }

  return {
    props: {
      ...(await getContent(contentItem)),
    },
  };
};

export const formatUrl = (url: string): string => {
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `http://${url}`;
};

/**
 * Returns the URL path segment based on the blog content type.
 *
 * @param type - The type of the blog content.
 * @returns The URL path segment for the given blog content type.
 */
export const getContentPath = (type: BlogTypeValues | string): string => {
  switch (type) {
    case BlogTypeValues.Vlog:
      return "video";
    case BlogTypeValues.Tip:
      return "tip";
    case BlogTypeValues.Blog:
    default:
      return "verhaal";
  }
};
